import React, { useEffect } from 'react'

function MuestraIntentos({ game, intentos }) {

    const intentoSyle = {
        match: {
            backgroundColor: 'lightgreen'
        },
        fail: {
            backgroundColor: 'red'
        },
        compartenContinente: {
            backgroundColor: 'lightblue'
        }
    }

    return (
        <div>
            {intentos.map((intento, index) => {                
                let estilo
                if (intento.idioma === game.idioma) {
                    estilo = intentoSyle.match
                } else {
                    let compartenContinente = false;
                    game.continentes.forEach(continente => {
                        intento.continentes.forEach(continenteDelIntento => {
                            if (continente === continenteDelIntento) {
                                compartenContinente = true
                            }
                        })
                    });
                    if (compartenContinente) {
                        estilo = intentoSyle.compartenContinente
                    } else {
                        estilo = intentoSyle.fail
                    }
                }
                return <h3 key={index} style={estilo}>{intento.idioma.toUpperCase()}</h3>
            })}
        </div>
    )
}

export default MuestraIntentos
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    player: {
        id: 'default',
        numberOfGames: -1,
        numberOfWins: -1,
    },
    game: {
        frase: 'Mędrzec nigdy nie mówi wszystkiego, co myśli, ale zawsze myśli wszystko, co mówi (Arystoteles)',
        idioma: "polish",
        continentes: ["europe"],
    },
}

export const counterSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setPlayer: (state, action) => {
            state.player = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDate, setPlayer } = counterSlice.actions

export default counterSlice.reducer
export const idiomasYContinentesArray = [
    { idioma: "afrikaans", continentes: ["africa"] },
    { idioma: "albanian", continentes: ["europe"] },
    { idioma: "amharic", continentes: ["africa"] },
    { idioma: "arabic", continentes: ["africa", "asia"] },
    { idioma: "armenian", continentes: ["europe", "asia"] },
    { idioma: "azerbaijani", continentes: ["europe", "asia"] },
    { idioma: "basque", continentes: ["europe"] },
    { idioma: "belarusian", continentes: ["europe"] },
    { idioma: "bengali", continentes: ["asia"] },
    { idioma: "bosnian", continentes: ["europe"] },
    { idioma: "bulgarian", continentes: ["europe"] },
    { idioma: "catalan", continentes: ["europe"] },
    { idioma: "cebuano", continentes: ["asia"] },
    { idioma: "chichewa", continentes: ["africa"] },
    { idioma: "chinese_simplified", continentes: ["asia"] },
    { idioma: "chinese_traditional", continentes: ["asia"] },
    { idioma: "corsican", continentes: ["europe"] },
    { idioma: "croatian", continentes: ["europe"] },
    { idioma: "czech", continentes: ["europe"] },
    { idioma: "danish", continentes: ["europe"] },
    { idioma: "dutch", continentes: ["europe"] },
    { idioma: "english", continentes: ["europe", "americas", "oceania", "africa", "asia"] },
    { idioma: "esperanto", continentes: ["worldwide"] },
    { idioma: "estonian", continentes: ["europe"] },
    { idioma: "filipino", continentes: ["asia"] },
    { idioma: "finnish", continentes: ["europe"] },
    { idioma: "french", continentes: ["europe", "africa", "americas", "asia"] },
    { idioma: "frisian", continentes: ["europe"] },
    { idioma: "galician", continentes: ["europe"] },
    { idioma: "georgian", continentes: ["europe", "asia"] },
    { idioma: "german", continentes: ["europe"] },
    { idioma: "greek", continentes: ["europe"] },
    { idioma: "gujarati", continentes: ["asia"] },
    { idioma: "haitian_creole", continentes: ["americas"] },
    { idioma: "hausa", continentes: ["africa"] },
    { idioma: "hawaiian", continentes: ["oceania"] },
    { idioma: "hebrew", continentes: ["asia"] },
    { idioma: "hindi", continentes: ["asia"] },
    { idioma: "hmong", continentes: ["asia"] },
    { idioma: "hungarian", continentes: ["europe"] },
    { idioma: "icelandic", continentes: ["europe"] },
    { idioma: "igbo", continentes: ["africa"] },
    { idioma: "indonesian", continentes: ["asia"] },
    { idioma: "irish", continentes: ["europe"] },
    { idioma: "italian", continentes: ["europe"] },
    { idioma: "japanese", continentes: ["asia"] },
    { idioma: "javanese", continentes: ["asia"] },
    { idioma: "kannada", continentes: ["asia"] },
    { idioma: "kazakh", continentes: ["europe", "asia"] },
    { idioma: "khmer", continentes: ["asia"] },
    { idioma: "kinyarwanda", continentes: ["africa"] },
    { idioma: "korean", continentes: ["asia"] },
    { idioma: "kurdish", continentes: ["asia"] },
    { idioma: "kyrgyz", continentes: ["asia"] },
    { idioma: "lao", continentes: ["asia"] },
    { idioma: "latin", continentes: ["worldwide"] },
    { idioma: "latvian", continentes: ["europe"] },
    { idioma: "lithuanian", continentes: ["europe"] },
    { idioma: "luxembourgish", continentes: ["europe"] },
    { idioma: "macedonian", continentes: ["europe"] },
    { idioma: "malagasy", continentes: ["africa"] },
    { idioma: "malay", continentes: ["asia"] },
    { idioma: "malayalam", continentes: ["asia"] },
    { idioma: "maltese", continentes: ["europe"] },
    { idioma: "maori", continentes: ["oceania"] },
    { idioma: "marathi", continentes: ["asia"] },
    { idioma: "mongolian", continentes: ["asia"] },
    { idioma: "myanmar", continentes: ["asia"] },
    { idioma: "nepali", continentes: ["asia"] },
    { idioma: "norwegian", continentes: ["europe"] },
    { idioma: "nyanja", continentes: ["africa"] },
    { idioma: "odia", continentes: ["asia"] },
    { idioma: "pashto", continentes: ["asia"] },
    { idioma: "persian", continentes: ["asia"] },
    { idioma: "polish", continentes: ["europe"] },
    { idioma: "portuguese", continentes: ["europe", "americas", "asia"] },
    { idioma: "punjabi", continentes: ["asia"] },
    { idioma: "romanian", continentes: ["europe"] },
    { idioma: "russian", continentes: ["europe", "asia"] },
    { idioma: "samoan", continentes: ["oceania"] },
    { idioma: "scots_gaelic", continentes: ["europe"] },
    { idioma: "serbian", continentes: ["europe"] },
    { idioma: "sesotho", continentes: ["africa"] },
    { idioma: "shona", continentes: ["africa"] },
    { idioma: "sindhi", continentes: ["asia"] },
    { idioma: "sinhala", continentes: ["asia"] },
    { idioma: "slovak", continentes: ["europe"] },
    { idioma: "slovenian", continentes: ["europe"] },
    { idioma: "somali", continentes: ["africa"] },
    { idioma: "spanish", continentes: ["europe", "americas", "africa", "asia"] },
    { idioma: "suahili", continentes: ["africa"] },
    { idioma: "sundanese", continentes: ["asia"] },
    { idioma: "swedish", continentes: ["europe"] },
    { idioma: "tajik", continentes: ["asia"] },
    { idioma: "tamil", continentes: ["asia"] },
    { idioma: "tatar", continentes: ["europe"] },
    { idioma: "telugu", continentes: ["asia"] },
    { idioma: "thai", continentes: ["asia"] },
    { idioma: "turkish", continentes: ["europe", "asia"] },
    { idioma: "turkmen", continentes: ["asia"] },
    { idioma: "ukrainian", continentes: ["europe"] },
    { idioma: "urdu", continentes: ["asia"] },
    { idioma: "uyghur", continentes: ["asia"] },
    { idioma: "uzbek", continentes: ["europe", "asia"] },
    { idioma: "vietnamese", continentes: ["asia"] },
    { idioma: "welsh", continentes: ["europe"] },
    { idioma: "xhosa", continentes: ["africa"] },
    { idioma: "yiddish", continentes: ["europe"] },
    { idioma: "yoruba", continentes: ["africa"] },
    { idioma: "zulu", continentes: ["africa"] }
]

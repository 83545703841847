import React, { useEffect } from 'react'
import './App.css'
import Home from './Screens/Home'

export default function App() {
 

  return (
    <Home/>
  )
}

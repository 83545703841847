import React, { useState } from 'react'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { idiomasYContinentesArray } from '../IdiomasYContinentes'

export default function MyAutocomplete({ handleClick }) {

    const [selected, setSelected] = useState()

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid style={{ margin: '5px' }} direction='row' container justifyContent="center" alignItems="center">
                <Autocomplete
                    freeSolo
                    disableClearable
                    clearOnBlur
                    selectOnFocus
                    id="combo-box-demo"
                    onChange={(event, value) => setSelected(value)}
                    options={idiomasYContinentesArray}
                    getOptionLabel={(option) => option.idioma ? option.idioma : ''}
                    sx={{ width: 100 }}
                    renderInput={(params) => <TextField {...params} label="Idioma" />}
                />
            </Grid>
            <Grid>
                <Button variant="contained" onClick={() => handleClick(selected)}>Enviar!</Button>
            </Grid>
        </Grid>
    )
}

import React, { useEffect, useState } from 'react'
import MyAutocomplete from '../Componentes/MyAutocomplete'
import { Alert, Box, Collapse, Container, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import MuestraIntentos from '../Componentes/MuestraIntentos'
import MensajeDialog from '../Componentes/MensajeDialog'



export default function Home() {

    const [open, setOpen] = useState(false)
    const [openFinal, setOpenFinal] = useState(false)
    const [alertMess, setAlertMess] = useState('Hola')
    const [intentos, setIntentos] = useState([])
    const [terminado, setTerminado] = useState(false)

    const game = useSelector(state => state.appState.game)

    const handleSend = (value) => {

        if (value === undefined) { return }
        if (value.continentes === undefined) { return }

        if (value) {
            setIntentos(prev => [...prev, value])
        } else {
            setOpen(true)
            setAlertMess("selecciona un idioma, gilipollas!")
            setTimeout(() => {
                setOpen(false)
            }, 2000);
        }
    }

    useEffect(() => {
        intentos.forEach(intento => {
            if (intento.idioma === game.idioma) {
                setOpenFinal(true)
                setTerminado(true)
            }
        })
    }, [intentos])

    return (
        <>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <h2>Wordle de Idiomas</h2>
                <h3 style={{ fontWeight: 400, fontSize: 23 }} >{game.frase}</h3>
                <p>¿Que idioma és?</p>
                {
                    intentos && <MuestraIntentos game={game} intentos={intentos} />
                }
                {
                    !terminado && <MyAutocomplete handleClick={handleSend} />
                }
                <MensajeDialog text={'No esta nada mal, lo has conseguido pedazo de gilipollas!'} open={openFinal} handleClose={setOpenFinal} />
                <Collapse in={open} >
                    <Alert sx={{ margin: "15px" }} severity='info' onClose={() => { setOpen(false) }}>{alertMess}</Alert>
                </Collapse>
                <div style={{ marginTop: 15 }}>
                    <span style={{backgroundColor: 'red'}}>Incorecto</span> | <span style={{backgroundColor: 'lightblue'}}>Se habla en el mismo continente</span> | <span style={{backgroundColor: 'lightgreen'}}>Correcto</span>
                </div>
            </Grid>
        </>

    )
}
